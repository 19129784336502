import { Call } from '../molecules';
import { FeatureToggle, ToggleOn } from '../atoms';
import { FEATURE_SCOPES, SCOPES } from '@data/auth';
import { useCallReceiver } from './hooks';

const CallReceiver: React.FC = () => {
  const { excludeCallingVehicles } = useCallReceiver();

  return (
    <FeatureToggle type="featureScope" scope={FEATURE_SCOPES.VoiceCall}>
      <ToggleOn>
        <FeatureToggle
          scope={[SCOPES.DescribeMediaStatus, SCOPES.ReceiveMediaStream]}
        >
          <ToggleOn>
            {excludeCallingVehicles.map((vehicle) => (
              <Call
                vehicleName={vehicle.vehicle_name}
                vehicleId={vehicle.vehicle_id}
                key={vehicle.vehicle_id}
              />
            ))}
          </ToggleOn>
        </FeatureToggle>
      </ToggleOn>
    </FeatureToggle>
  );
};

export default CallReceiver;
