import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { isPlacesLoadedAtom } from '@data/fms/place/states';
import type { Place } from '@data/fms/place/types';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 停車地点一覧取得API
 */
export const usePlacesAPI = (): {
  state: APIState;
  getPlaces: () => Promise<Place[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getPlacesAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<Place[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/places`,
        );
        if (isNullOrUndefined(res?.data)) return new Success([]);
        // 昇順ソート
        res.data.sort((a: Place, b: Place) => {
          if (a.name < b.name) return -1;
          return 1;
        });
        return new Success(res.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getPlaces = useAtomCallback(
    useCallback(
      async (get, set) => {
        setState('loading');
        set(isPlacesLoadedAtom, false);
        if (!getHasScope(SCOPES.DescribePlace)) {
          setState('hasError');
          return [];
        }

        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (isNullOrUndefined(project) && isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.get_places', { status: 'failed' }),
          });
          setState('hasError');
          set(isPlacesLoadedAtom, true);
          return [];
        }
        const res = await getPlacesAPI(
          project!.id,
          environment!.environment_id,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_places', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          set(isPlacesLoadedAtom, true);
          setState('hasError');
          return [];
        }
        set(isPlacesLoadedAtom, true);
        setState('hasValue');
        return res.value;
      },
      [notifyError, getPlacesAPI, getHasScope, t, getErrorMessage],
    ),
  );

  return {
    state,
    getPlaces,
  };
};

/**
 * 指定エリアマップバージョンの停車地点一覧取得API
 */
export const useAreaMapVersionPlacesAPI = (): {
  state: APIState;
  getAreaMapVersionPlaces: (areaMapVersionId: string) => Promise<Place[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getAreaMapVersionPlacesAPI = useCallback(
    async (
      projectId: string,
      areaMapId: number,
      areaMapVersionId: string,
    ): Promise<Result<Place[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/area_maps/${areaMapId}/versions/${areaMapVersionId}/places`,
        );
        if (isNullOrUndefined(res?.data)) return new Success([]);
        // 昇順ソート
        res.data.sort((a: Place, b: Place) => {
          if (a.name < b.name) return -1;
          return 1;
        });
        return new Success(res.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getAreaMapVersionPlaces = useAtomCallback(
    useCallback(
      async (get, _, areaMapVersionId: string) => {
        setState('loading');
        if (!getHasScope(SCOPES.DescribePlace)) {
          setState('hasError');
          return [];
        }

        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (isNullOrUndefined(project) && isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.get_places', { status: 'failed' }),
          });
          setState('hasError');
          return [];
        }
        const res = await getAreaMapVersionPlacesAPI(
          project!.id,
          environment!.area_map_id,
          areaMapVersionId,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_places', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
      [
        notifyError,
        getAreaMapVersionPlacesAPI,
        getHasScope,
        t,
        getErrorMessage,
      ],
    ),
  );

  return {
    state,
    getAreaMapVersionPlaces,
  };
};
