import { useState, memo } from 'react';
import { Paper as MuiPaper, styled, Box } from '@mui/material';
import { spacing } from '@mui/system';
import { Header, Sidebar } from '@components/common/organisms';
import { headerHeightSelector } from '@data/maintenance';
import { useAtomValue } from 'jotai';

export const drawerWidth = 260;

const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const headerHeight = useAtomValue(headerHeightSelector);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (
    <Root>
      <Sidebar
        open={mobileOpen}
        onClose={handleDrawerToggle}
        onSidebarLinkClick={handleDrawerClose}
        variant="temporary"
        sx={{
          display: { xs: 'block', xl: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      />
      <Sidebar
        variant="permanent"
        sx={{
          display: { xs: 'none', xl: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      />
      <AppContent
        sx={{
          width: { xs: '100%', xl: `calc(100% - ${drawerWidth}px)` },
          marginLeft: { xs: 0, xl: `${drawerWidth}px` },
        }}
      >
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent
          sx={{
            height: `calc(100vh - ${headerHeight}px)`,
          }}
        >
          {children}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default memo(Layout);
