import {
  connectedVehiclesSelector,
  remoteMonitorConfigAtom,
} from '@data/fms/vehicle/states';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

export const useCallReceiver = () => {
  const connectedVehicles = useAtomValue(connectedVehiclesSelector(false));
  const remoteMonitorConfig = useAtomValue(remoteMonitorConfigAtom);

  /**
   * 遠隔監視画面を開いている車両は除外
   */
  const excludeCallingVehicles = useMemo(() => {
    if (!remoteMonitorConfig?.vehicle) {
      return connectedVehicles;
    }
    return connectedVehicles.filter(
      (vehicle) =>
        vehicle.vehicle_id !== remoteMonitorConfig.vehicle!.vehicle_id,
    );
  }, [connectedVehicles, remoteMonitorConfig]);

  return {
    excludeCallingVehicles,
  };
};
