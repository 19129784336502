import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useMount } from 'react-use';
import { MediaClient } from '@tier4/webauto-media-client';
import { usePostAuthorizationAPI } from '@api/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useAtomValue } from 'jotai';

export type CallState =
  | 'init'
  | 'ready'
  | 'calling'
  | 'ringing'
  | 'talking'
  | 'error';

function getLocalStream() {
  return navigator.mediaDevices.getUserMedia({
    video: false,
    audio: true,
  });
}

export const useRemoteCall = (
  vehicleId: string,
): {
  callState: CallState;
  setState: Dispatch<SetStateAction<CallState>>;
  setIsCancel: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
} => {
  const getToken = usePostAuthorizationAPI();
  const environment = useAtomValue(environmentAtom);
  const [call, setCall] = useState<MediaClient.RemoteCall>();
  const [isCancel, setIsCancel] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ringing, setRinging] = useState<MediaClient.Ringing>();
  const [callState, setState] = useState<CallState>('init');
  const [stream, setStream] = useState<MediaStream>();
  const [talking, setTalking] = useState<MediaClient.Conversation>();
  const [calling, setCalling] = useState<MediaClient.Calling>();

  const ringtone = useMemo(() => {
    const audio = new Audio('/assets/sound/ringtone.mp3');
    audio.loop = true;
    audio.muted = false;
    return audio;
  }, []);

  useMount(() => {
    MediaClient.addAuthTokenCallback(async () => {
      const token = await getToken();
      return {
        token: token.accessToken,
        expiredAt: new Date(token.expiry * 1000),
      };
    });
  });

  useMount(() => {
    if (!environment) return;
    const callObject = MediaClient.createRemoteCall(
      {
        projectId: environment.project_id,
        vehicleId,
        environmentId: environment.environment_id,
        role: 'operator',
      },
      setRinging,
    );
    setCall(callObject);
    setState('ready');
  });

  /* ringing */
  useEffect(() => {
    if (!ringing) return;
    if (calling || talking || stream) return;
    switch (callState) {
      case 'ready':
        setState('ringing');
        break;

      case 'ringing':
        (async (ringingObject: MediaClient.Ringing) => {
          try {
            await ringtone.play();
            setIsError(false);
          } catch (e) {
            console.error(e);
          }
          if (isCancel) {
            ringingObject.refuse();
            setRinging(undefined);
            setIsCancel(false);
            setState('ready');
            ringtone.pause();
            return;
          }
          try {
            await ringingObject.waitForCancel();
          } catch (e) {
            setIsError(true);
            console.warn(`done for cancel: ${e}`);
          }
          ringtone.pause();
          if (isCancel) return;
          setRinging(undefined);
          setState('ready');
          setIsCancel(false);
        })(ringing);
        break;

      case 'talking':
        (async (ringingObject: MediaClient.Ringing) => {
          const talkingStream = await getLocalStream();
          if (isCancel) {
            talkingStream.getTracks().forEach((t) => t.stop());
            setIsCancel(false);
            return;
          }
          ringtone.pause();
          const talkingObject = await ringingObject.answer(talkingStream);
          if (isCancel) {
            talkingObject.hangUp();
            talkingStream.getTracks().forEach((t) => t.stop());
            setIsCancel(false);
            return;
          }
          setStream(talkingStream);
          setRinging(undefined);
          setTalking(talkingObject);
        })(ringing);
        break;
      default:
        break;
    }
  }, [callState, ringing, calling, talking, stream, ringtone, isCancel]);

  /* calling */
  useEffect(() => {
    if (callState !== 'calling') return;
    if (!call) return;

    (async () => {
      const callingStream = await getLocalStream();
      if (isCancel) {
        setStream(callingStream);
        const callingObjecrt = call.call('operator', callingStream);
        setCalling(callingObjecrt);
        return;
      }
      if (!calling) return;
      try {
        const talkingObject = await calling.waitForAnswer();
        if (isCancel) {
          talkingObject.hangUp();
          callingStream.getTracks().forEach((t) => t.stop());
          setIsCancel(false);
          return;
        }
        setCalling(undefined);
        setState('talking');
        setTalking(talkingObject);
      } catch {
        callingStream.getTracks().forEach((t) => t.stop());
        setStream(undefined);
        setCalling(undefined);
        setState('ready');
      }
    })();
  }, [callState, call, calling, isCancel]);

  /* stream */
  useEffect(() => {
    if (!talking) return;
    if (!stream) return;
    if (callState !== 'talking') return;

    (async (talkingObject) => {
      const audio = new Audio();
      audio.srcObject = talkingObject.stream;
      try {
        await audio.play();
      } catch (error) {
        setIsError(true);
        console.error(`cannot play coming stream: ${error}`);
      }
      if (isCancel) {
        talkingObject.hangUp();
        stream.getTracks().forEach((t) => t.stop());
        return;
      }

      try {
        await talkingObject.waitForHangUp();
      } catch (e) {
        setIsError(true);
        console.debug(e);
      }
      stream.getTracks().forEach((t) => t.stop());
      if (isCancel) {
        return;
      }
      setStream(undefined);
      setTalking(undefined);
      setState('ready');
    })(talking);
  }, [callState, talking, stream, isCancel]);

  /* ready */
  useEffect(() => {
    if (callState !== 'ready') return;

    if (calling) {
      calling.cancel();
      setCalling(undefined);
    }

    if (talking) {
      talking.hangUp();
      setTalking(undefined);
    }

    if (stream) {
      stream.getTracks().forEach((t) => t.stop());
      setStream(undefined);
    }
  }, [callState, talking, calling, stream, isCancel]);

  return {
    callState,
    setState,
    setIsCancel,
    isError,
  };
};
